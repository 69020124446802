import { makeAutoObservable } from 'mobx';

interface ToasterType {
  id: string;
  label: string;
}

/**
 * @description Toaster 기능을 수행하는 Store 입니다.
 */
export default class ToasterModel {
  toaster: ToasterType | null;

  constructor() {
    this.toaster = null;

    makeAutoObservable(this);
  }

  toast = (label?: string) => {
    if (label) {
      this.toaster = {
        id: Math.random().toString(),
        label: label
      };
    }
  };

  clearToast = () => {
    this.toaster = null;
  };
}
