/**
 * AuthManager.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 로그인 토큰을 관리하는 매니저입니다.
 * 토큰을 이용해 유저의 기초 정보를 가져올 때 사용합니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

import { verifyLoginToken } from 'common-modules/general/API/user';
import { User } from 'common-modules/general/API/user/types';
import { makeAutoObservable } from 'mobx';
import { RoutesString } from 'common-modules/general/Config/routesString';
import CustomError from './ErrorModel';

export default class AuthManager {
  public static storageTokenKey = '__login_token__';
  public static storageIdKey = '__login_id__';

  public userData: User | null;

  public userDataLoading: boolean;

  constructor() {
    this.userData = null;
    this.userDataLoading = true;

    makeAutoObservable(this);
  }

  public static getLoginTokenOnStorage = (): string | null => {
    try {
      const token = localStorage.getItem(AuthManager.storageTokenKey);

      if (token && typeof token === 'string') {
        return token;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  public static getLoginIDOnStorage = (): string | null => {
    try {
      const token = localStorage.getItem(AuthManager.storageIdKey);

      if (token && typeof token === 'string') {
        return token;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  private setUserDataLoading = (loading: boolean) => {
    this.userDataLoading = loading;
  };

  private setLoginTokenOnLocalStorage = (token: string, id?: string, isSaveId?: boolean) => {
    try {
      isSaveId && id && localStorage.setItem(AuthManager.storageIdKey, id);
      localStorage.setItem(AuthManager.storageTokenKey, token);
    } catch (e) {
      return null;
    }
  };

  private clearLoginTokenOnLocalStorage = () => {
    try {
      localStorage.removeItem(AuthManager.storageTokenKey);
    } catch (e) {
      return null;
    }
  };

  clearSavedId = () => {
    try {
      localStorage.removeItem(AuthManager.storageIdKey);
    } catch (e) {
      return null;
    }
  };

  setUserData = (userData: User | null) => {
    this.userData = userData;
  };

  updateUserdata = (key: keyof User, value: string) => {
    if (this.userData) {
      this.setUserData({ ...this.userData, [key]: value });
    }
  };

  setAuthData = ({
    token,
    userData,
    isSaveId,
    saveId
  }: {
    token: string;
    saveId?: string;
    isSaveId?: boolean;
    userData?: User;
  }) => {
    this.setLoginTokenOnLocalStorage(token, saveId, isSaveId);
    userData && this.setUserData(userData);
  };

  upToDateUserDataToToken = (): Promise<User> => {
    const token = AuthManager.getLoginTokenOnStorage();

    if (token && !this.userData) {
      this.setUserDataLoading(true);

      return verifyLoginToken()
        .then(({ data }) => {
          this.setUserData(data);

          return Promise.resolve(data);
        })
        .catch(e => {
          this.initAuthData();

          return Promise.reject(e);
        })
        .finally(() => {
          this.setUserDataLoading(false);
        });
    } else {
      this.setUserDataLoading(false);

      return Promise.reject(new CustomError({ message: '유효하지 않은 요청입니다.' }));
    }
  };

  initAuthData = (redirectURL = true) => {
    this.clearLoginTokenOnLocalStorage();
    this.setUserData(null);

    if (redirectURL) {
      location.href = RoutesString.Main;
    }
  };
}
