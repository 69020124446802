/**
 * useGaTracker.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * GA(Google Analytics) 페이지 트래킹을 위한 내용을 다룹니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { DEV_END_POINT, EndPointURL } from 'common-modules/general/Config/constants';
import TagManager from 'react-gtm-module';
import { cdBizLnd, cdBizNm } from 'data/configs';

/**
 * @name useGaTracker
 * @description * GA(Google Analytics) 페이지 트래킹을 위한 컴포넌트
 * 해당 컴포넌트는 App.tsx에서 렌더 전 호출하는 것으로 마무리 됩니다.
 */
const useGaTracker = () => {
  const location = useLocation();
  const [initiailze, setInitialize] = useState(false);

  /**
   * 기본적으로 이 GA는 운영서버에 배포될 빌드만 허용합니다.
   * 따라서 개발용(localhost)나 개발 서버(dev.dlcon-apt.co.kr)의 경우 허용하지 않도록 변수를 설정합니다.
   */
  useEffect(() => {
    if (!window.location.href.includes('localhost') && EndPointURL !== DEV_END_POINT) {
      ReactGA.initialize([
        {
          trackingId: 'UA-47552166-1',
          gaOptions: {
            name: 'B'
          }
        },
        {
          trackingId: process.env.REACT_APP_GA_ID || '',
          gaOptions: {
            name: 'S'
          }
        }
      ]);

      // ReactGA.initialize(process.env.REACT_APP_GA_ID || '');
      // if (process.env.REACT_APP_GA_ID !== undefined) {
      //   setInitialize(true);
      // }

      // 2023-05-30 GTM 코드 추가
      const tagManagerArgs = {
        gtmId: 'GTM-PKP2HFH'
      };
      TagManager.initialize(tagManagerArgs);

      setInitialize(true);
    }
  }, []);

  /**
   * 위 useEffect에서 정상적으로 setInitailize(true)가 호출될 경우 페이지 이동을 감지해 해당 내용을 GA에 전송합니다.
   */
  useEffect(() => {
    if (initiailze) {
      ReactGA.pageview(location.pathname + location.search, ['B', 'S']);

      // 2023-05-30 GTM 테스트용 봉선/헤이리/구미상모/서울산/스카이마리나
      if (
        cdBizLnd.includes('91XAS7') ||
        cdBizLnd.includes('221047') ||
        cdBizLnd.includes('221091') ||
        cdBizLnd.includes('211097') ||
        cdBizLnd.includes('221068')
      ) {
        TagManager.dataLayer({
          dataLayer: {
            house_name: cdBizNm,
            code: cdBizLnd
          }
        });
      }
    }
  }, [initiailze, location]);
};

export default useGaTracker;
