/**
 * APIClientModel.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 기본 API 클라이언트 모델입니다.
 * 이 모델을 바탕으로 통신이 이뤄지며 해당 통신의 기초 모델입니다. 별도의 수정은 필요 없습니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { APIClientBaseURL } from 'common-modules/general/Config/constants';
import MobxStore from 'common-modules/general/Store/MobxStore';
import AuthManager from './AuthManager';

class APIClientModel {
  static defaultClientSettings: AxiosRequestConfig<any> = {
    timeout: 5000 // 타임아웃 시간 설정
  };

  axiosClient: AxiosInstance;

  constructor(config: AxiosRequestConfig<any> = {}) {
    this.axiosClient = axios.create({
      ...config,
      ...APIClientModel.defaultClientSettings,
      validateStatus: APIClientModel.validateStatus
    });

    this.axiosClient.interceptors.request.use(this.requestSuccessInterceptor, this.requestFailureInterceptor);
    this.axiosClient.interceptors.response.use(this.responseSuccessInterceptor, this.responseFailureInterceptor);
  }

  static validateStatus = (status: number): boolean => {
    return status >= 200 && status < 300;
  };

  /**
   * @description Axios 통신 요청이 성공했을 경우
   */
  requestSuccessInterceptor = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
    return {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${AuthManager.getLoginTokenOnStorage()}`
      }
    };
  };

  /**
   * @description Axios 통신 요청이 실패했을 경우
   */
  requestFailureInterceptor = (error: any) => {
    return Promise.reject(error);
  };
  /**
   * @description Axios 통신이 성공했을 경우
   */
  responseSuccessInterceptor = (response: AxiosResponse<any, any>) => {
    return response;
  };

  /**
   * @description Axios 통신이 실패했을 경우
   */
  responseFailureInterceptor = (error: any) => {
    const { authManager } = MobxStore();
    /* Login token이 유효하지 않을 경우, Login 해제 및 token 삭제 */
    const loginTokenErrorCode: Array<number> = [];

    if (loginTokenErrorCode.includes(error?.response?.status)) {
      authManager.initAuthData();
    }

    return Promise.reject(error);
  };
}

type APIClientTypes = {
  [key in keyof typeof APIClientBaseURL]: AxiosInstance;
};

const getAPIClientList = (): APIClientTypes => {
  const clientList = {};

  Object.entries(APIClientBaseURL).forEach(([key, value]) => {
    clientList[key] = new APIClientModel({
      baseURL: value
    }).axiosClient;
  });

  return clientList as APIClientTypes;
};

/* 최종적으로 사용하게 되는 API Client */
const APIClient: APIClientTypes = getAPIClientList();

export default APIClient;
