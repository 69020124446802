/**
 * MainCommon.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * (메인 브랜드 페이지용 MainCommon)
 * 서버 API로부터 기본 데이터를 전달받도록 제어하는 기본 로직이 정의.
 * 아래의 데이터들을 불러옵니다.
 * contentsOrder: ResponseContentsOrder[]; // 콘텐츠의 표출 순서.
 * salesList: ResponseSaleList[]; // GNB 분양 메뉴에 표기될 분양지 리스트.
 * mainVisual: ResponseMainVisual; // 메인 비주얼 콘텐츠 정보.
 * sales: ResponseSaleContents[]; // 메인 비주얼 분양지 카드 리스트 정보.
 * shortcuts: ResponseShortCutList[]; // 메인 비주얼 바로가기 리스트 정보.
 * popup: MainContentsPopup | null; // 팝업 여부. 존재하면 null이 아님
 * banner: TopBannerModel | null; // 배너 여부. 존재하면 null이 아님
 * -----------------------------------------------------------------------------------------------
 */
 import { ResponseMainContents } from 'common-modules/general/API/main/types';
 import MobxAsyncModel from 'common-modules/general/Model/MobxAsyncModel';
 import { getMainContentsData } from 'common-modules/general/API/main/index';
 import { makeAutoObservable } from 'mobx';
 
 export default class MainCommonModel {
   public mainData: MobxAsyncModel<ResponseMainContents>;
 
   constructor() {
     this.mainData = new MobxAsyncModel(true);
 
     makeAutoObservable(this);
   }
 
   getData = () => {
     getMainContentsData()
       .then(({ data }) => {
         this.mainData.loadSuccess(data);
       })
       .catch(() => {
         this.mainData.loadFailure();
       });
   };
 }
 