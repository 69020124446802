/**
 * routesString.tsx
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 참조 가능한 이동 가능한 URL 모음입니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

 import { EndPointURL } from 'common-modules/general/Config/constants';
 const SalesRouteLink = {
   Main: `/`,
   Brand: `${EndPointURL}/brand`,
   Bi: `${EndPointURL}/brand/bi`,
   Skill: `${EndPointURL}/brand/skill`,
   Product: `${EndPointURL}/brand/product`,
   BrandNews: `${EndPointURL}/brand/news`,
   MyPage: `${EndPointURL}/myHome`,
   FamilRV: `${EndPointURL}/myHome/family`,
   FamilyRVTime: `${EndPointURL}/myHome/family/time`,
   Ventilation: `${EndPointURL}/myHome/ventilation`,
   LivingGuide: `${EndPointURL}/myHome/livingGuide`,
   MoveIn: `${EndPointURL}/myHome/moveIn`,
   MoveInTime: `${EndPointURL}/myHome/moveIn/time`,
   BookingInfor: `${EndPointURL}/myHome/moveIn/booking`,
   Payment: `${EndPointURL}/myHome/payment`,
   PaymentApart: `${EndPointURL}/myHome/payment/apart`,
   Interest: `${EndPointURL}/myHome/payment/interest`,
   Separately: `${EndPointURL}/myHome/payment/separately`,
   Separately2: `${EndPointURL}/myHome/payment/separately2`,
   Separately3: `${EndPointURL}/myHome/payment/separately3`,
   Complex: `${EndPointURL}/myHome/complex`,
   ComplexInfo: `${EndPointURL}/myHome/complex/info`,
   AsSection01: `${EndPointURL}/myHome/asSection01`,
   AsSection02: `${EndPointURL}/myHome/asSection02`,
   Agree: `${EndPointURL}/agree`,
   Person: `${EndPointURL}/person`,
   FooterQuestion: `${EndPointURL}/footer/question`,
   FooterQuestion2: `${EndPointURL}/footer/question2`,
   FooterQuestion3: `${EndPointURL}/footer/question3`,
   FooterQuestion4: `${EndPointURL}/footer/question4`,
   FooterQuestion5: `${EndPointURL}/footer/question5`,
   FooterNotice: `${EndPointURL}/footer/footerNotice01`,
   FooterNotice2: `${EndPointURL}/footer/footerNotice02`,
   FooterFoundList: `${EndPointURL}/footer/footerFound_list`,
   FooterEvent01: `${EndPointURL}/footer/footerEvent01`,
   FooterEvent02: `${EndPointURL}/footer/footerEvent02`,
   SearchResult: `${EndPointURL}/footer/SearchResult`,
   FooterPop: `${EndPointURL}/footer/pop`,
   AsApply: `${EndPointURL}/myHome/asApply`,
   Email: `${EndPointURL}/email`,
   Policy: `${EndPointURL}/footer/policy`,
   History: `${EndPointURL}/brand/history`,
   Sitemap: `${EndPointURL}/footer/footerSitemap`,
   ChangeInfo: `${EndPointURL}/auth/change`,
   ChangePW: `${EndPointURL}/auth/changePW`,
   JoinMember: `${EndPointURL}/auth/joinMember`,
   ConfirmPassword: '/auth/confirmPw',
   FindId: `${EndPointURL}/auth/findId`,
   FindPw: `${EndPointURL}/auth/findPw`,
   IntersetComplex: `${EndPointURL}/auth/interset`,
   Secession: `${EndPointURL}/auth/secession`,
   Laboratory: `${EndPointURL}/footer/laboratory`,
   Login: `${EndPointURL}/auth/login`,
   ContractInfo: `${EndPointURL}/myHome/contract`,
   ContractInfoChange: `${EndPointURL}/myHome/contract/change`,
   OverLap: `${EndPointURL}/auth/joinMember/overLap`,
   BrandHistory: `${EndPointURL}/brand/history`,
   DormantAccount: `${EndPointURL}/auth/dormant`,
   Schedule: `${EndPointURL}/sales/Schedule`,
   Prototype: `${EndPointURL}/prototype`,
   Reapply: `${EndPointURL}/reapply`,
   FindAllId: `${EndPointURL}/auth/findAllId`,
   InitPassword: `${EndPointURL}/auth/initPw`,
   ChangePWRefeat: `${EndPointURL}/auth/changePWRefeat`,
   LocationVerify: `${EndPointURL}/locationVerify`,
 
   CodingList: '/codingList', // 코딩 리스트
   SalesOverView: '/sales/',
   SalesInfo: '/sales/info',
   SalesLocation: '/sales/location',
   SalesExhbt: '/sales/exhbt',
   SalesHousehold: '/sales/household',
   SalesNews: '/sales/news',
   SalesNewsDetail: '/sales/news/detail',
   SalesEvent: '/sales/event',
   SalesEventDetail: '/sales/event/detail',
   SalesStreet: '/sales/street',
   SalesReserve: '/sales/reserve',
   SalesReserveDetail: '/sales/reserve/detail',
   SalesReserveApply: '/sales/reserveApply',
   SalesReserveList: '/sales/reserveList',
   SalesReserveComplete: '/sales/reserveComplete'
 };
 
 const BrandRouteLink = {
   Main: `/`,
   Brand: '/brand',
   Bi: '/brand/bi',
   Skill: '/brand/skill',
   Product: '/brand/product',
   BrandNews: '/brand/news',
   MyPage: '/myHome',
   FamilRV: '/myHome/family',
   FamilyRVTime: '/myHome/family/time',
   Ventilation: '/myHome/ventilation',
   LivingGuide: '/myHome/livingGuide',
   MoveIn: '/myHome/moveIn',
   MoveInTime: '/myHome/moveIn/time',
   BookingInfor: '/myHome/moveIn/booking',
   Payment: '/myHome/payment',
   PaymentApart: '/myHome/payment/apart',
   Interest: '/myHome/payment/interest',
   Separately: '/myHome/payment/separately',
   Separately2: '/myHome/payment/separately2',
   Separately3: '/myHome/payment/separately3',
   Complex: '/myHome/complex',
   ComplexInfo: '/myHome/complex/info',
   AsSection01: '/myHome/asSection01',
   AsSection02: '/myHome/asSection02',
   Agree: '/agree',
   Person: '/person',
   FooterQuestion: '/footer/question',
   FooterQuestion2: '/footer/question2',
   FooterQuestion3: '/footer/question3',
   FooterQuestion4: '/footer/question4',
   FooterQuestion5: '/footer/question5',
   FooterNotice: '/footer/footerNotice01',
   FooterNotice2: '/footer/footerNotice02',
   FooterFoundList: '/footer/footerFound_list',
   FooterEvent01: '/footer/footerEvent01',
   FooterEvent02: '/footer/footerEvent02',
   SearchResult: '/footer/SearchResult',
   FooterPop: '/footer/pop',
   AsApply: '/myHome/asApply',
   Email: '/email',
   Policy: '/footer/policy',
   History: '/brand/history',
   Sitemap: '/footer/footerSitemap',
   ChangeInfo: '/auth/change',
   ChangePW: '/auth/changePW',
   JoinMember: '/auth/joinMember',
   ConfirmPassword: '/auth/confirmPw',
   FindId: '/auth/findId',
   FindPw: '/auth/findPw',
   IntersetComplex: '/auth/interset',
   Secession: '/auth/secession',
   Laboratory: '/footer/laboratory',
   Login: '/auth/login',
   ContractInfo: '/myHome/contract',
   ContractInfoChange: '/myHome/contract/change',
   OverLap: '/auth/joinMember/overLap',
   BrandHistory: '/brand/history',
   DormantAccount: '/auth/dormant',
   Schedule: '/sales/Schedule',
   Prototype: '/prototype',
   Reapply: '/reapply',
   FindAllId: '/auth/findAllId',
   InitPassword: '/auth/initPw',
   ChangePWRefeat: '/auth/changePWRefeat',
   LocationVerify: '/locationVerify',
   
   LoginNonMbrBtn: '/auth/loginNonMbrBtn'
 };
 
 const getRouteLink = () => {
   const isMain = process.env.REACT_APP_IS_BRAND;
   if (isMain && isMain == 'BRAND') {
     return { ...SalesRouteLink, ...BrandRouteLink };
     // return BrandRouteLink;
   } else {
     return SalesRouteLink;
   }
 };
 
 export const RoutesString = getRouteLink();
  