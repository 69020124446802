import 'core-js/stable';
import { configure } from 'mobx';

configure({
  useProxies: 'never'
});

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import './assets/fontFace.scss';

import App from './App';
import ToasterContainer from 'Components/Common/Components/Toaster/ToasterContainer';
import ErrorBoundary from 'common-modules/general/Modules/class-component/ErrorBoundary';
import { EndPointURL, REAL_END_POINT } from 'common-modules/general/Config/constants';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ToasterContainer isMobile={isMobile} />
    {/* <GlobalFont /> */}
    <ErrorBoundary isMobile={isMobile}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// React 성능측정 도구는 리얼 서버가 아니라면 활성화 하지 않는다.
if (EndPointURL !== REAL_END_POINT) {
  reportWebVitals();
}

const userAgent = navigator.userAgent.toLowerCase();
const target_url = window.location.origin;

// if (userAgent.match(/kakaotalk/i)) {
if (userAgent.includes('kakaotalk')) {
  window.location.href = 'kakaotalk://web/openExternal?url=' + encodeURIComponent(target_url);
} else {
  if (target_url.toLowerCase().includes('daelimcon-apt')) {
    window.location.href = "https://www.dlcon-apt.co.kr";
  }

  if (!target_url.toLowerCase().includes('dev') && (target_url.toLowerCase().indexOf('www') == -1)) {
    window.location.href = window.location.origin.replace("//", "//www.");
  }
}


// if (window.location.origin.toLowerCase().includes('daelimcon-apt')) {
//   // 'daelimcon-apt'가 EndPointURL에 포함되어 있는 경우
//   window.location.href = "https://www.dlcon-apt.co.kr";
// }

// if (!window.location.origin.toLowerCase().includes('dev')) {
//   if (window.location.origin.toLowerCase().indexOf('www') == -1) {
//     // 운영 url에 www가 포함되어 있지 않은 경우
//     window.location.href = window.location.origin.replace("//", "//www.");
//   }
// }

