import { makeAutoObservable } from 'mobx';

/**
 * @description Mobile 버전에서의 Header Title과 관련된 데이터를 저장하는 Store 입니다.
 */
export default class MobileHeader {
  mobileHeaderTitle: string | null;

  constructor() {
    this.mobileHeaderTitle = null;

    makeAutoObservable(this);
  }

  setMobileHeaderTitle = (title: string | null) => {
    this.mobileHeaderTitle = title;
  };
}
