/**
 * ToasterContainer.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 *  토스터를 커스텀하여 사용하는 컴포넌트 입니다.
 * 
 * -----------------------------------------------------------------------------------------------
 */

import MobxStore from 'common-modules/general/Store/MobxStore';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';

import checkIcon from '../../../../assets/Desktop/images/common/ico_check02.png';
import closeIcon from '../../../../assets/Desktop/images/common/ico_close02.png';

const ToasterComponent = styled.div<{ isMobile: boolean }>`
  @keyframes toaster-fade-in {
    from {
      transform: translateY(60px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes toaster-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  width: 100%;
  height: max-content;
  max-width: 1200px;
  max-width: 75rem;
  padding: 18px;
  margin: 0 auto;
  background: #ff671d;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 80px;

  ${props =>
    props.isMobile &&
    css`
      width: 90%;
      font-size: 0.9375rem;
      margin-top: 3.5rem;
      top: 0;

      ${ToastCloseButton} {
        margin-right: 0px;
      }
    `};

  &.toaster-fade-in {
    animation: toaster-fade-in 0.3s forwards;
  }

  &.toaster-fade-out {
    animation: toaster-fade-out 0.3s forwards;
  }
`;

const Label = styled(InnerHTML)`
  flex: 1;
  line-height: 1.4;
  text-align: left;
`;

const Icon = styled.img`
  margin-right: 1.125rem;
  width: 1.5rem;
  height: 1.5rem;
`;

const ToastCloseButton = styled.img`
  width: 19px;
  height: 19px;
  margin: auto 20px;

  opacity: 1;
  cursor: pointer;
`;

interface Props {
  isMobile: boolean;
}

const ToasterAutoClose = 5 * 1000;
const ToasterStartAnimationDelete = 3 * 100;

const ToasterContainer = observer(({ isMobile }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  let autoCloseTimer: NodeJS.Timeout | null = null;
  let startAnimationTimer: NodeJS.Timeout | null = null;
  let animationDeleteTimer: NodeJS.Timeout | null = null;

  const { toaster } = MobxStore();

  const addClassname = (name: string) => {
    if (containerRef.current) {
      containerRef.current.classList.add(name);
    }
  };

  const removeClassname = (name: string) => {
    if (containerRef.current) {
      containerRef.current.classList.remove(name);
    }
  };

  reaction(
    () => toaster.toaster,
    (present, prev) => {
      if ((prev && present && prev?.id !== present?.id) || (!prev && present)) {
        clear();

        startAnimationTimer = setTimeout(() => {
          /* Start animation 없애기 */
          removeClassname('toaster-fade-in');
        }, ToasterStartAnimationDelete);

        animationDeleteTimer = setTimeout(() => {
          /* End animation 시작 */
          addClassname('toaster-fade-out');
        }, ToasterAutoClose - ToasterStartAnimationDelete);

        autoCloseTimer = setTimeout(() => {
          /* 토스터 데이터 제거 및 초기화 */
          toaster.clearToast();
          clear();
        }, ToasterAutoClose);
      }
    }
  );

  const clear = () => {
    autoCloseTimer && clearTimeout(autoCloseTimer);
    startAnimationTimer && clearTimeout(startAnimationTimer);
    animationDeleteTimer && clearTimeout(animationDeleteTimer);
  };

  const handleClose = () => {
    addClassname('toaster-fade-out');

    animationDeleteTimer = setTimeout(() => {
      toaster.clearToast();
      clear();
    }, ToasterStartAnimationDelete);
  };

  if (toaster.toaster) {
    return (
      <ToasterComponent key={toaster.toaster.id} ref={containerRef} className={`toaster-fade-in`} isMobile={isMobile}>
        <Icon src={checkIcon} />
        <Label html={toaster.toaster.label} />
        <ToastCloseButton onClick={handleClose} src={closeIcon} />
      </ToasterComponent>
    );
  } else {
    return null;
  }
});

export default ToasterContainer;
