/**
 * type.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * /auth API
 * 유저와 관련된 모든 영역에서 사용되는 API 인터페이스
 *
 * -----------------------------------------------------------------------------------------------
 */

import APIClient from 'common-modules/general/Model/APIClientModel';

import {
  RequestChangeEmailAPI,
  RequestChangePasswordAPI,
  RequestCheckPasswordAPI,
  RequestDormantAccountFromEmail,
  RequestDormantAccountFromPhone,
  RequestEmailOverlapAPI,
  RequestExpireVerifyVerfiyCodeAPI,
  RequestFindAllIdAPI,
  RequestFindIdAPI,
  RequestIdOverlapAPI,
  RequestInitPasswordAPI,
  RequestLoginWithIdAPI,
  RequestLoginWithPhoneAPI,
  RequestMulitLoginAPI,
  RequestReapplyParam,
  RequestSendEmailVerifyCodeAPI,
  RequestSendEmailVerifyCodeForNonUserAPI,
  RequestSendPhoneVerifyCodeAPI,
  RequestSendSmsLoginVerifyCodeAPI,
  RequestSignUp,
  RequestSmsLoginWithPhoneAPI,
  RequestUpdateUserDataAPI,
  RequestVerifyEmailVerifyCodeOfFindPasswordAPI,
  RequestVerifyPhoneAPI,
  RequestVerifyPhoneVerfiyCodeOfFindPasswordAPI,
  RequestVerifyPhoneVerifyCodeAPI,
  RequestWithdrawalAPI,
  ResponseChangeEmailAPI,
  ResponseChangePasswordAPI,
  ResponseCheckPasswordAPI,
  ResponseDormantAccountFromEmail,
  ResponseDormantAccountFromPhone,
  ResponseEmailOverlapAPI,
  ResponseFindAllIdAPI,
  ResponseFindIdAPI,
  ResponseIdOverlapAPI,
  ResponseInitPasswordAPI,
  ResponseLoginWithIdAPI,
  ResponseLoginWithPhoneAPI,
  ResponseMulitLoginAPI,
  ResponseReapply,
  ResponseSendEmailVerifyCodeAPI,
  ResponseSendPhoneVerifyCodeAPI,
  ResponseSendSmsLoginVerifyCodeAPI,
  ResponseSignUp,
  ResponseSmsLoginWithPhoneAPI,
  ResponseUpdateUserDataAPI,
  ResponseVerifyPhoneVerfiyCodeOfFindPasswordAPI,
  ResponseVerifyPhoneVerifyCodeAPI,
  ResponseWithdrawalAPI,
  ResponVerifyEmailVerifyCodeOfFindPasswordAPI,
  User
} from './types';

/**
 * 로그인(아이디 방식) API 요청
 */
export const loginWithId = (data: RequestLoginWithIdAPI) => APIClient.auth.post<ResponseLoginWithIdAPI>('/signin/id', data);

/**
 * 로그인(휴대전화 방식) API 요청
 */
export const loginWithPhone = (data: RequestLoginWithPhoneAPI) => APIClient.auth.post<ResponseLoginWithPhoneAPI>('/signin/phone', data);

/**
 * 비회원 로그인(휴대전화 방식) API 요청
 */
 export const smsLoginWithPhone = (data: RequestSmsLoginWithPhoneAPI) => APIClient.auth.post<ResponseSmsLoginWithPhoneAPI>('/signin/phone/nonMbr', data);

/**
 * 회원가입 API 요청
 */
export const signUp = (data: RequestSignUp) => APIClient.auth.post<ResponseSignUp>('/signup', data);

/**
 * 로그인 토큰으로 토큰 인증 API 요청
 */
export const verifyLoginToken = () => APIClient.auth.post<User>('/verify');

/**
 * 회원가입 API 요청
 */
export const verifyPhoneVerifyCode = (data: RequestVerifyPhoneVerifyCodeAPI) => APIClient.auth.post<ResponseVerifyPhoneVerifyCodeAPI>('/sms/verify', data);

/**
 * 핸드폰 인증번호 발송 API 요청
 */
export const sendPhoneVerifyCode = (data: RequestSendPhoneVerifyCodeAPI) => APIClient.auth.post<ResponseSendPhoneVerifyCodeAPI>('/sms/send', data);

/**
 * 인증번호 5회 이상 틀릴 시 API 요청
 */
export const expireVerifyCode = (data: RequestExpireVerifyVerfiyCodeAPI) => APIClient.auth.post<ResponseSendPhoneVerifyCodeAPI>('/sms/expire', data);


/**
 * 비회원 로그인 인증번호 발송 API 요청(23.03.16)
 */
 export const sendSmsLoginVerifyCode = (data: RequestSendPhoneVerifyCodeAPI) => APIClient.auth.post<ResponseSendSmsLoginVerifyCodeAPI>('/sms/nonMbr/send', data);

/**
 * 이메일 인증번호 발송 API 요청
 */
export const sendEmailVerifyCode = (data: RequestSendEmailVerifyCodeAPI) => APIClient.auth.post<ResponseSendEmailVerifyCodeAPI>('/mail/send', data);

/**
 * 이메일 인증번호 발송 API 요청 (비회원 요청)
 */
export const sendEmailVerifyCodeForNonUser = (data: RequestSendEmailVerifyCodeForNonUserAPI) => APIClient.auth.post('/mail/send/n', data);

/**
 * 비밀번호 초기화 API 요청
 */
export const initPassword = (data: RequestInitPasswordAPI) => APIClient.auth.post<ResponseInitPasswordAPI>('/privacy/init/pw', data);

/**
 * 아이디 찾기 API 요청
 */
export const findId = (data: RequestFindIdAPI) => APIClient.auth.post<ResponseFindIdAPI>('/privacy/find/id', data);

/**
 * 전체 아이디 찾기 API 요청
 */
export const findAllId = (data: RequestFindAllIdAPI) => APIClient.auth.post<ResponseFindAllIdAPI>('/privacy/find/id/full', data);

/**
 * 전체 아이디 찾기 API 요청
 */
export const verifyPhoneVerfiyCodeOfFindPassword = (data: RequestVerifyPhoneVerfiyCodeOfFindPasswordAPI) => APIClient.auth.post<ResponseVerifyPhoneVerfiyCodeOfFindPasswordAPI>('/privacy/find/pw/phone', data);

/**
 * 전체 아이디 찾기 API 요청
 */
export const verifyEmailVerifyCodeOfFindPassword = (data: RequestVerifyEmailVerifyCodeOfFindPasswordAPI) => APIClient.auth.post<ResponVerifyEmailVerifyCodeOfFindPasswordAPI>('/privacy/find/pw/mail', data);

/**
 * 회원 탈퇴 API 요청
 */
export const changeEmail = (data: RequestChangeEmailAPI) => APIClient.auth.post<ResponseChangeEmailAPI>('/privacy/edit/mail', data);

/**
 * 회원 탈퇴 API 요청
 */
export const changePassword = (data: RequestChangePasswordAPI) => APIClient.auth.post<ResponseChangePasswordAPI>('/privacy/edit/pw', data);

/**
 * 회원 탈퇴 API 요청
 */
export const updateUserData = (data: RequestUpdateUserDataAPI) => APIClient.auth.post<ResponseUpdateUserDataAPI>('/privacy/edit', data);

/**
 * 회원 탈퇴 API 요청
 */
export const checkPassword = (data: RequestCheckPasswordAPI) => APIClient.auth.post<ResponseCheckPasswordAPI>('/privacy/check', data);

/**
 * 회원 탈퇴 API 요청
 */
export const checkIdOverlap = (data: RequestIdOverlapAPI) => APIClient.auth.post<ResponseIdOverlapAPI>('/check/id', data);

/**
 * 회원 탈퇴 API 요청
 */
export const checkEmailOverlap = (data: RequestEmailOverlapAPI) => APIClient.auth.post<ResponseEmailOverlapAPI>('/check/email', data);

/**
 * 회원 탈퇴 API 요청
 */
export const withdrawal = (data: RequestWithdrawalAPI) => APIClient.auth.post<ResponseWithdrawalAPI>('/privacy/withdrawal', data);

/**
 * 회원 탈퇴 API 요청
 */
export const dormantAccountFromEmail = (data: RequestDormantAccountFromEmail) => APIClient.auth.post<ResponseDormantAccountFromEmail>('/dormant/email', data);

/**
 * 회원 탈퇴 API 요청
 */
export const dormantAccountFromPhone = (data: RequestDormantAccountFromPhone) => APIClient.auth.post<ResponseDormantAccountFromPhone>('/dormant/phone', data);

/**
 * 멀티 로그인(휴대폰 번호) API 요청
 */
export const multiLogin = (data: RequestMulitLoginAPI) => APIClient.auth.post<ResponseMulitLoginAPI>('/signin/phone/multi', data);

/**
 * NICE 인증 API 요청
 */
export const niceVerify = (type: 'A' | 'B' | 'C') => APIClient.auth.get<string>(`/nice/encrypt?type=${type}`, { withCredentials: true });

/**
 * 개인정보 재설정 API 요청
 */
 export const reapply = (data: RequestReapplyParam) => APIClient.auth.post<ResponseReapply>('/privacy/reapply', data);

 /**
 * 핸드폰 인증번호 발송 API 요청
 */
export const sendAgreePhoneVerifyCode = (data: RequestVerifyPhoneAPI) => APIClient.auth.post<ResponseSendPhoneVerifyCodeAPI>('/sms/verify/send', data);
