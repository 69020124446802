/**
 * ErrorModel.ts
 * [Readme] --------------------------------------------------------------------------------------
 * 
 * 커스텀 에러 모델입니다.
 * 어느 특정한 에러의 예외처리를 지정할 수 있습니다.
 * 
 * -----------------------------------------------------------------------------------------------
 */

interface IErrorModel {
  code?: number;
  message?: string;
}

export default class CustomError {
  code?: number;
  message?: string;

  constructor({ code, message }: IErrorModel) {
    this.code = code;
    this.message = message;
  }

  public static getIsCustomError = (error: any): boolean => {
    return Boolean(error instanceof CustomError);
  };
}
