/**
 * ErrorBoundary.tsx
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 에러 바운더리 페이지 컴포넌트.
 * 지정되지 않은 예외 상황이 발생했을 시 활성화 되는 페이지입니다.
 * 
 * -----------------------------------------------------------------------------------------------
 */

import PCErrorFallback from './Desktop';
import MobileErrorFallback from './Mobile';
import { Component } from 'react';

interface Props {
  isMobile: boolean;
}

interface State {
  hasError: boolean;
}

/**
 * @description 에러가 발생했을때 오류 페이지를 보여주는 클래스입니다.
 */
export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.isMobile ? <MobileErrorFallback /> : <PCErrorFallback />;
    } else {
      return this.props.children;
    }
  }
}
