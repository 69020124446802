import { VerifyResponse } from 'common-modules/general/API/contract/types';
import { LoginWithPhoneUserInfo, RequestSendSmsLoginVerifyCodeAPI, User } from 'common-modules/general/API/user/types';
import { makeAutoObservable } from 'mobx';

export interface FindIDAccess {
  id: string;
  name: string;
}

export interface MyHomeVerifyFieldCode {
  locationCode: string;
  dong: string;
  ho: string;
  name: string;
  dtBrth: string;

  verifyData?: VerifyResponse;
  carnoyn: string;
}

/**
 * @description 유저 접근 권한과 관련된 데이터를 저장하는 Store 입니다.
 */
export default class UserAccessModel {
  /* 중복 계정 데이터 */
  duplicationIdList: Array<LoginWithPhoneUserInfo> | null;
  /* 휴면 계정 페이지 접근 Access 데이터 */
  dormantAccountAccess: User | null;
  /* 아이디 찾기 성공 데이터 */
  findIdAccess: FindIDAccess | null;
  /* 비밀번호 찾기 성공 데이터 */
  findPWAccessToken: string | null;
  /* 개인정보 변경 페이지 접근 Access 데이터 */
  editUserAccountAccess: boolean;
  /* 마이홈 종합정보 및 분양페이지용 단지 인증 데이터  */
  myHomeVerifyFieldCodeList: Array<MyHomeVerifyFieldCode>;
  /* 마이홈 종합정보 및 분양 대금 납부 조회시 마지막으로 선택한 분양지 */
  lastSelectedCommonBusinessPlace: string | null;
  /* 개인정보 재설정 token 데이터 */
  privacyResetToken: string | null;
  /* 특정 관리자 계정 여부 */
  adminIdTp: string | '';

  /* 고객문의 비회원 로그인 인증 데이터 */
  nonMbrVerifyData: RequestSendSmsLoginVerifyCodeAPI | null;

  constructor() {
    this.duplicationIdList = [];
    this.dormantAccountAccess = null;
    this.findIdAccess = null;
    this.findPWAccessToken = null;
    this.editUserAccountAccess = false;
    this.myHomeVerifyFieldCodeList = [];
    this.lastSelectedCommonBusinessPlace = null;
    this.privacyResetToken = null;
    this.adminIdTp = '';

    this.nonMbrVerifyData = null;

    makeAutoObservable(this);
  }

  setPrivacyResetToken = (token: string | null) => {
    this.privacyResetToken = token;
  };

  setLastSelectedCommonBusinessPlace = (lastSelectedCommonBusinessPlace: string | null) => {
    this.lastSelectedCommonBusinessPlace = lastSelectedCommonBusinessPlace;
  };

  private setMyHomeVerifyFieldCodeList = (verfiyCodeList: Array<MyHomeVerifyFieldCode>) => {
    this.myHomeVerifyFieldCodeList = verfiyCodeList;
  };

  addHomeVerifyFieldCode = (code: MyHomeVerifyFieldCode) => {
    this.setMyHomeVerifyFieldCodeList(this.myHomeVerifyFieldCodeList.concat([code]));
  };

  initHomeVerifyFieldCode = () => {
    this.setMyHomeVerifyFieldCodeList([]);
  };

  findVerifyField = (code: string): MyHomeVerifyFieldCode | null => {
    return this.myHomeVerifyFieldCodeList.find(({ locationCode }) => locationCode === code) || null;
  };

  setEditUserAccountAccess = (access: boolean) => {
    this.editUserAccountAccess = access;
  };

  setFindPWAccessToken = (token: string) => {
    this.findPWAccessToken = token;
  };

  initFindPWAccessToken = () => {
    this.findPWAccessToken = null;
  };

  initDuplicationIdList = () => {
    this.duplicationIdList = null;
  };

  initFindIdAccess = () => {
    this.findIdAccess = null;
  };

  setFindIdAccess = (findIdAccess: FindIDAccess) => {
    this.findIdAccess = findIdAccess;
  };

  setDuplicationIdList = (idList: Array<LoginWithPhoneUserInfo>) => {
    this.duplicationIdList = idList;
  };

  setDormantAccountAccess = (userData: User | null) => {
    this.dormantAccountAccess = userData;
  };

  initAdminIdTp = () => {
    this.adminIdTp = '';
  };

  setAdminIdTp = (admin: string) => {
    this.adminIdTp = admin;
  };

  initNonMbrVerifyData = () => {
    this.nonMbrVerifyData = null;
  };
  setNonMbrVerifyData = (nonMbrVerifyData: RequestSendSmsLoginVerifyCodeAPI) => {
    this.nonMbrVerifyData = nonMbrVerifyData;
  };
}
