import { RoutesString } from 'common-modules/general/Config/routesString';
import './index.scss';

const ErrorFallback = () => {
  const handleClickMainPage = () => {
    window.location.href = RoutesString.Main;
  };

  return (
    <div className="mobile-error-fallback-page">
      <div className="mobile-notFound-page-contents">
        <div className="title">
          죄송합니다. <br /> 일시적인 페이지 오류입니다.
        </div>
        <div className="description">
          해당 페이지에서 예기치 못한 오류가 발생했습니다.
          <br />
          자세한 문의는 고객센터 (080-535-3000)를 이용해 주세요.
        </div>
        <div className="button" onClick={handleClickMainPage}>
          e 편한세상 홈으로
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
