/**
 * App.tsx
 * [Readme] --------------------------------------------------------------------------------------
 *
 * index.tsx에서 접근하는 실질적인 리액트 앱 컴포넌트의 시작점입니다.
 * ※웬만한 경우 이 영역을 조작할 필요는 없습니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

import useLimitAccessRouter from 'common-modules/general/Modules/custom-hooks/useLimitAccessRouter';
import MobxStore from 'common-modules/general/Store/MobxStore';
import { observer, useLocalObservable } from 'mobx-react';
import { lazy, useEffect, useLayoutEffect, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import MainCommonModel from 'common-modules/general/Common/Logic/Main/MainCommon';
import { useLocation } from 'react-router-dom';
import useGaTracker from 'common-modules/general/Modules/custom-hooks/useGaTracker';

const DesktopRoutesWrapper = lazy(() => import('./Components/Desktop/Routers/routes'));
const MobileRoutesWrapper = lazy(() => import('./Components/Mobile/Routers/routes'));

const DesktopWrapper = styled.div`
  min-width: 1300px;
  position: relative;
`;
const MobileWrapper = styled.div``;

// isMobile이라는 변수를 통해 모바일 유저인지 파악을 먼저 합니다.
// 모바일유저 라면 MobileRotesWrapper로 pc유저라면 DesktopRotesWrapper로 분기를 탑니다.
const App = observer(() => {
  const { authManager } = MobxStore();

  const location = useLocation();

  useLimitAccessRouter();

  useEffect(() => {
    authManager.upToDateUserDataToToken();
  }, []);

   /**
   * GA 트래킹을 시작합니다.
   * /src/useGaTracker.tsx 참고.
   */
  useGaTracker();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    authManager.upToDateUserDataToToken();
  }, [location.pathname]);

  const { model: mainModel } = useLocalObservable(() => ({ model: new MainCommonModel() }));

  useEffect(() => {
    mainModel.getData();
  }, [mainModel]);

  return (
    <Suspense fallback={<></>}>
      {!authManager.userDataLoading &&
        (isMobile ? (
          <MobileWrapper>
            <MobileRoutesWrapper mainModel={mainModel} />
          </MobileWrapper>
        ) : (
          <DesktopWrapper>
            <DesktopRoutesWrapper mainModel={mainModel} />
          </DesktopWrapper>
        ))}
    </Suspense>
  );
});

export default App;
