/**
 * index.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 이 파일은 서버로부터 데이터를 받는 진입점을 설정합니다.
 * 서버로부터 분양페이지의 기본 데이터를 제공 받습니다.
 *
 * -----------------------------------------------------------------------------------------------
 */

import APIClient from 'common-modules/general/Model/APIClientModel';
import { cdBizLnd } from 'data/configs';

import {
  RequestFindConstructionAPI,
  ResponseFindBiz,
  ResponseFindConstructionAPI,
  ResponseMainContents,
  ResponseSaleAllList,
  ResponseSaleList,
  ResponseSalesInfo
} from './types';

export const getMainContentsData = () => APIClient.main.get<ResponseMainContents>('');

export interface IGetFindELifeData {
  nmBizLnd?: string;
  cdArea?: string;
  cdPrgsStat?: string;
  page?: number;
}

/**
 * 기본 분양페이지 정보 가져오는 API
 * 필수 파라미터로 단지 코드를 입력
 */
export const getSalesMainData = () => APIClient.sales.get<ResponseSalesInfo>(`${cdBizLnd}`);

export const getFindELifeData = ({ nmBizLnd, cdArea, cdPrgsStat, page }: IGetFindELifeData) =>
  APIClient.main.get<ResponseFindBiz>(`/find?nmBizLnd=${nmBizLnd ? encodeURIComponent(nmBizLnd) : ''}&cdArea=${
    cdArea ? encodeURIComponent(cdArea) : ''
  }&cdPrgsStat=${cdPrgsStat ? cdPrgsStat : ''}&page=${page ? page : 1}
`);

export const getSalesList = () => APIClient.main.get<ResponseSaleList[]>('sales');

export const getAllSalesList = () => APIClient.main.get<ResponseSaleAllList[]>('sales/all');

export const getFindConstruction = (data: RequestFindConstructionAPI) =>
  APIClient.main.get<ResponseFindConstructionAPI>('/find/construction', {
    params: data
  });
