/**
 * constants.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 본 프로젝트의 기초 설정을 다룹니다. *
 *
 * -----------------------------------------------------------------------------------------------
 */

// API 기본 호출 주소입니다.
export const DEV_END_POINT = 'https://dev.dlcon-apt.co.kr';
export const REAL_END_POINT = 'https://www.dlcon-apt.co.kr';
// export const REAL_END_POINT = 'https://dev.dlcon-apt.co.kr';
// export const REAL_END_POINT = 'http://localhost:8080';
export const EndPointURL = process.env.NODE_ENV !== 'production' ? REAL_END_POINT : window.location.origin;

// 202311.내부보안으로 인해 외부IP 차단된 상태(허용된 IP만 접속 가능)
// export const SBASSFileEndPointURL =
//   EndPointURL === DEV_END_POINT ? 'https://sbassdev.dlconstruction.co.kr' : 'https://sbass.dlconstruction.co.kr';

const prefixURL = '/api/brand';

export const EmailDomainList = ['naver.com', 'daum.net', 'hanmail.net', 'nate.com', 'gmail.com', 'kakao.com', '직접 입력'];

export const APIClientBaseURL = Object.freeze({
  default: `${EndPointURL}${prefixURL}`,
  main: `${EndPointURL}${prefixURL}/main`,
  auth: `${EndPointURL}${prefixURL}/auth`,
  support: `${EndPointURL}${prefixURL}/support`,
  brand: `${EndPointURL}${prefixURL}/brand`,
  contract: `${EndPointURL}${prefixURL}/contract`,
  home: `${EndPointURL}${prefixURL}/home`,
  sales: `${EndPointURL}${prefixURL}/sales`,
  service: `${EndPointURL}${prefixURL}/service`,
  watchList: `${EndPointURL}${prefixURL}/watchlist`,
  file: `${EndPointURL}/upload`,
  // SBASSFile: `${SBASSFileEndPointURL}`
});

export const SecessionReasonList = [
  'ID 변경을 위해',
  '사이트 이용 불편',
  '전반적인 서비스 불만족',
  '유용한 콘텐츠 부족',
  '시스템 불안정',
  '특별한 사유 없음',
  '기타'
];

export const zIndex = Object.freeze({
  slideMenu: 8
});

export const administrator = ['csmaster', 'h2100877', 'h2001334', 'dawon0304'];
