/**
 * MobxAsyncModel.ts
 * [Readme] --------------------------------------------------------------------------------------
 * 
 * 통신처리에서 state 변동 처리를 위한 모델입니다.
 * 
 * -----------------------------------------------------------------------------------------------
 */

import { makeAutoObservable } from 'mobx';

export type TFetchState = 'PENDING' | 'FAILURE' | 'READY';

/**
 * 통신 처리에서 state 변동 처리를 위한 클래스
 */
export default class MobxAsyncModel<T> {
  data: T | null;
  loadStatus: TFetchState;

  constructor(immediatelyLoad: boolean = false) {
    this.data = null;
    this.loadStatus = 'READY';

    makeAutoObservable(this);

    if (immediatelyLoad) {
      this.tryLoad();
    }
  }

  setLoadStatus = (loadStatus: TFetchState) => {
    this.loadStatus = loadStatus;
  };

  setData = (data: T | null) => {
    this.data = data;
  };

  /**
   * 통신 준비 상태를 설정합니다.
   * 별도의 초기화를 지정하고싶지 않다면 본 메서드는 생략 가능합니다.
   */
  init = () => {
    this.setLoadStatus('READY');
    this.setData(null);
  };

  /**
   * 통신 성공 플래그를 설정합니다.
   * 성공 시 서버가 반환해준 데이터를 저장할 수 있습니다.
   */
  loadSuccess = (data: T) => {
    this.setLoadStatus('READY');
    this.setData(data);
  };

  /**
   * 통신 실패 플래그를 설정합니다.
   */
  loadFailure = (clearData: boolean = false) => {
    this.setLoadStatus('FAILURE');
    clearData && this.setData(null);
  };

  /**
   * 통신을 진행한다는 플래그를 설정합니다. (PENDING)
   */
  tryLoad = (clearData: boolean = false) => {
    this.setLoadStatus('PENDING');
    clearData && this.setData(null);
  };
}
