import MobxStore from 'common-modules/general/Store/MobxStore';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import usePrevious from './usePrevious';

const useLimitAccessRouter = () => {
  const { routerAccessCallback } = MobxStore();
  const history = useHistory();
  const prevLocation = usePrevious(history.location);

  useEffect(() => {
    if (prevLocation && history.location && prevLocation.pathname !== history.location.pathname) {
      const prevCallback = routerAccessCallback.findRouterCallback(prevLocation.pathname);
      const presentCallback = routerAccessCallback.findRouterCallback(history.location.pathname);

      if (prevCallback) {
        prevCallback.out?.();
      }

      if (presentCallback) {
        presentCallback.enter?.();
      }
    }
  }, [history.location]);

  return null;
};

export default useLimitAccessRouter;
