/**
 * configs.ts
 * [Readme] --------------------------------------------------------------------------------------
 *
 * 본 파일은 원래는 분양페이지에서만 사용하는 고정 단지 코드이나
 * 공통화 모듈에서 이를 참조하는 구간이 있어 더미로 만든 상수입니다.
 * 아래 상수는 브랜드 프로젝트에 어떠한 영향을 미치지 않으나 제거는 하지 말아주세요.
 *
 * -----------------------------------------------------------------------------------------------
 */

/**
 * @global
 * @description * 단지 코드 정보입니다.
 * cdBizNm : useGaTracker.tsx 공통파일에서 GTM 호출을 위한 변수
 */

 export const cdBizLnd = '000000';
 export const cdBizNm = '';