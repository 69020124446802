import { makeAutoObservable } from 'mobx';

export interface RouterAccessCallback {
  router: string;
  enter?: () => void;
  out?: () => void;

  onOutAfterAutoClear?: boolean;
}

/**
 * @description Router 이동 시 실행될 여러 Callback들을 저장하는 Store 입니다.
 */
export default class RouterAccess {
  accessRouterCallbackList: Array<RouterAccessCallback>;

  constructor() {
    this.accessRouterCallbackList = [];

    makeAutoObservable(this);
  }

  findRouterCallback = (routerPath: string) => {
    return this.accessRouterCallbackList.find(({ router }) => router === routerPath) || null;
  };

  setRouterAccessCallback = (data: RouterAccessCallback) => {
    this.accessRouterCallbackList = this.accessRouterCallbackList.filter(({ router }) => router !== data.router).concat([data]);
  };

  clearRouterAccessCallback = (routerPath: string) => {
    this.accessRouterCallbackList = this.accessRouterCallbackList.filter(({ router }) => router !== routerPath);
  };
}
