import AuthManager from 'common-modules/general/Model/AuthManager';
import MobileHeader from './mobileHeader';
import Toaster from './toaster';
import UserAccess from './userAccess';
import RouterAccessCallback from './routerAccessCallback';

let authManager: AuthManager | null = null;
let userAccess: UserAccess | null = null;
let toaster: Toaster | null = null;
let mobileHeader: MobileHeader | null = null;
let routerAccess: RouterAccessCallback | null = null;

const MobxStore = () => {
  const _authManager = authManager || new AuthManager();
  const _userAccess = userAccess || new UserAccess();
  const _toaster = toaster || new Toaster();
  const _mobileHeader = mobileHeader || new MobileHeader();
  const _routerAccess = routerAccess || new RouterAccessCallback();

  !authManager && (authManager = _authManager);
  !userAccess && (userAccess = _userAccess);
  !toaster && (toaster = _toaster);
  !mobileHeader && (mobileHeader = _mobileHeader);
  !routerAccess && (routerAccess = _routerAccess);

  return {
    authManager: _authManager,
    userAccess: _userAccess,
    toaster: _toaster,
    mobileHeader: _mobileHeader,
    routerAccessCallback: routerAccess
  };
};

export default MobxStore;
